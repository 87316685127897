import { StringSchema } from "yup";
import BaseSchema from "yup/lib/schema";
import { AnyObject, Maybe, Message } from "yup/lib/types";

declare module "yup" {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        notInList(list?: string[], message?: Message<{ list?: string[] }>): this;
    }
}

const notInList = function (this: StringSchema, list?: string[], message?: Message<{ list?: string[] }>) {
    return this.test({
        name: "notInList",
        message: message ?? `\${path} is not in allowed values`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            if (!value) {
                return true;
            }
            const isBlocked = list?.some((code) => code == value);
            return !isBlocked;
        },
    });
};

StringSchema.prototype.notInList = notInList;
