import dayjs from "dayjs";
import { StringSchema } from "yup";
import BaseSchema from "yup/lib/schema";
import { AnyObject, Maybe, Message } from "yup/lib/types";

import { dateFormatDefault, earliestValidDate } from "@/Core/Constants";

declare module "yup" {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        validDate(message?: Message<{ minimumAge: number }>): this;
    }
}

const validDate = function (this: StringSchema, message?: Message<{ minimumAge: number }>) {
    return this.test({
        name: "validDate",
        message: message ?? `\${path} is invalid date`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            const dateHundredYearsAgo = dayjs(earliestValidDate);
            const dateValue = dayjs(value, dateFormatDefault);
            return !value ? true : dateValue.isValid() && dateValue >= dateHundredYearsAgo;
        },
    });
};

StringSchema.prototype.validDate = validDate;
