import valid from "card-validator";
import { StringSchema } from "yup";
import BaseSchema from "yup/lib/schema";
import { AnyObject, Maybe, Message } from "yup/lib/types";

declare module "yup" {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        cardExpirationDate(message?: Message<{ value?: string }>): this;
    }
}

const cardExpirationDate = function (this: StringSchema, message?: Message<{ cvv?: string }>) {
    return this.test({
        name: "expiration",
        message: message ?? `\${path} is invalid`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            return valid.expirationDate(value, 10).isValid;
        },
    });
};

StringSchema.prototype.cardExpirationDate = cardExpirationDate;
