import dayjs from "dayjs";
import { StringSchema } from "yup";
import BaseSchema from "yup/lib/schema";
import { AnyObject, Maybe, Message } from "yup/lib/types";

declare module "yup" {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        minAge(minimumAge?: number, message?: Message<{ minimumAge?: number }>): this;
    }
}

const minAge = function (this: StringSchema, minimumAge?: number, message?: Message<{ minimumAge?: number }>) {
    return this.test({
        name: "minAge",
        message: message ?? `\${path} can't be less than ${minimumAge} years old`,
        params: { minimumAge },
        exclusive: true,
        test: (value: Maybe<string>) => {
            if (!minimumAge) {
                return true;
            }
            const dateMinimum = dayjs().subtract(minimumAge, "year");
            if (!value || !dateMinimum.isValid()) {
                return true;
            }

            const dateValue = dayjs(value);
            return !dateValue.isValid() || dateValue <= dateMinimum;
        },
    });
};

StringSchema.prototype.minAge = minAge;
