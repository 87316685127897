import { IStringUtilities } from "@/Core/Utilities/IStringUtilities";

export default class StringUtilities implements IStringUtilities {
    public toPascalCase(value: string) {
        return value
            .replace(/[-_]+/g, " ")
            .replace(/[^\w\s]/g, "")
            .replace(/\s+(.)(\w*)/g, ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
            .replace(/\w/, (s) => s.toUpperCase());
    }
}
