import { StringSchema } from "yup";
import BaseSchema from "yup/lib/schema";
import { AnyObject, Maybe, Message } from "yup/lib/types";

declare module "yup" {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        validYear(message?: Message<{ year: number }>): this;
    }
}

const validYear = function (this: StringSchema, message?: Message<{ year: number }>) {
    return this.test({
        name: "validYear",
        message: message ?? `Please provide a valid numeric \${path} (from 1900 onwards)`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            if (value && isNaN(+value)) return false;
            if (Number(value) < 1900) return false;
            return true;
        },
    });
};

StringSchema.prototype.validYear = validYear;
