import { StringSchema } from "yup";
import BaseSchema from "yup/lib/schema";
import { AnyObject, Maybe, Message } from "yup/lib/types";

declare module "yup" {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        cardCvv(message?: Message<{ value?: string }>): this;
    }
}

const cardCvv = function (this: StringSchema, message?: Message<{ cvv?: string }>) {
    return this.test({
        name: "cvv",
        message: message ?? `\${path} is invalid`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            if (!value) {
                return false;
            }

            const isNumeric = /^\d+$/.test(value);
            return isNumeric && (value.toString().length === 3 || value.toString().length === 4);
        },
    });
};

StringSchema.prototype.cardCvv = cardCvv;
