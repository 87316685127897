import valid from "card-validator";
import { StringSchema } from "yup";
import BaseSchema from "yup/lib/schema";
import { AnyObject, Maybe, Message } from "yup/lib/types";

declare module "yup" {
    interface StringSchema<TType extends Maybe<string> = string | undefined, TContext extends AnyObject = AnyObject, TOut extends TType = TType>
        extends BaseSchema<TType, TContext, TOut> {
        cardNumber(message?: Message<{ value?: string }>): this;
    }
}

const cardNumber = function (this: StringSchema, message?: Message<{ cvv?: string }>) {
    return this.test({
        name: "cardNumber",
        message: message ?? `Please check your \${path} and try again`,
        exclusive: true,
        test: (value: Maybe<string>) => {
            return valid.number(value).isValid;
        },
    });
};

StringSchema.prototype.cardNumber = cardNumber;
